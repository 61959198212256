export default class DeptRequestItem {
    page = 1;
    pageSize = 10;
    parentId: String = "O-1D-1";
    defaultId: String = "O-1D-1";
    search = false;
    searchField = "all";
    searchKey = "";
    cType: [string, number] = null;
}
