import { Options, Vue } from "vue-class-component";
import Modal from "@/components/modal/modal.vue";
import DropdownSearch from "@/modules/dropdown-search/dropdown-search.vue";
import DeptItem from "./entity/DeptItem";
import DeptPageItems from "./entity/DeptPageItems";
import DeptRequestItem from "./entity/DeptRequestItem";
import { useToast } from "vue-toastification";
import DeptAPI from "@/api/system/master/dept";

@Options({
    name: "app-select-dept",
    components: {
        "app-modal": Modal,
        "app-dropdown-search": DropdownSearch,
    },
    props: {
        levelType: {
            type: String,
            default: "show", //show|select|noDrill|onLest
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        selectFunction: Function,
        searchFieldList: {
            type: Object,
            default: {},
        },
        selectItem: {
            type: Object,
            default: {},
        },
        maxParentId: {
            type: String,
            default: "O-1D-1",
        },
        defaultId: {
            type: String,
            default: "O-1D-1",
        },
    },
})
export default class SelectDept extends Vue {
    private isOpen = false;
    private deptSelectModalElement: any;
    private multiple = false;
    protected isLoading = false;
    protected toast = useToast();
    private maxParentId = "O-1D-1";
    private defaultId = "O-1D-1";
    private selectItem: any = {};
    private selectFunction: any;
    // private value: any = null;
    // private title: any = null;
    private cType: [string, number] = null;
    private selected: any = {};

    public onValueChange(event: any, item: any) {
        if (event.target.nodeName !== "SPAN") {
            if (this.selected.hasOwnProperty(item.id)) {
                if (!this.multiple) this.selected = {};
                delete this.selected[item.id];
            } else {
                if (!this.multiple) this.selected = {};
                this.selected[item.id] = { parent: item.parent, title: item.name, value: item.id };
            }
            this.selected = { ...this.selected };
        }
    }

    public isSelected(id: any) {
        return this.selected.hasOwnProperty(id);
    }

    public async submitFunction(): Promise<any> {
        const selectedArray = Object.values(this.selected);
        if (!this.multiple) {
            //单选
            if (this.$tools.isEmpty(selectedArray)) {
                return await this.selectFunction({});
            } else {
                return await this.selectFunction(selectedArray[0]);
            }
        } else {
            //多选
            return await this.selectFunction(selectedArray);
        }
    }

    public async mounted(): Promise<void> {
        this.deptSelectModalElement = this.$refs.deptSelectModal;
    }

    //搜索功能开始
    protected deptRequestItem: DeptRequestItem = new DeptRequestItem();
    protected searchCancel() {
        this.deptRequestItem.search = false;
        this.deptRequestItem.searchField = "all";
        this.deptRequestItem.searchKey = "";
        this.getList(1, this.deptRequestItem.parentId);
    }
    protected searchFunction(filed: string) {
        if (this.$tools.isEmpty(this.deptRequestItem.searchKey)) {
            this.searchCancel();
        } else {
            this.deptRequestItem.search = true;
            this.deptRequestItem.searchField = filed;
            this.getList(1, this.deptRequestItem.parentId);
        }
    }
    //搜索功能结束

    //列表功能开始
    protected deptPageItems: DeptPageItems = new DeptPageItems();
    protected deptItems: Array<DeptItem> = [];
    protected getList(page: number, parentId: String): void {
        if (parentId.length < 1) {
            this.toast.error("上级ID不能为空");
            return;
        }
        //支持默认值
        if (this.isOpen && this.defaultId.length > 0) {
            this.deptRequestItem.defaultId = this.defaultId;
            this.isOpen = false;
        } else {
            this.deptRequestItem.defaultId = "O-1D-1";
        }
        this.deptRequestItem.cType = this.cType;
        this.deptRequestItem.parentId = parentId;
        if (page > 0) this.deptRequestItem.page = page;
        this.isLoading = true;
        DeptAPI.levelSelect(this.deptRequestItem)
            .then((res: any) => {
                this.deptPageItems.total = res.data.total;
                this.deptPageItems.pages = res.data.pages;
                this.deptPageItems.current = res.data.current;
                this.deptPageItems.size = res.data.size;
                this.deptPageItems.pid = res.data.pid;
                this.deptPageItems.pName = res.data.pName;
                this.deptItems = res.data.records;

                //为支持默认ID，需要回写新的上级ID
                if (res.data.pid) this.deptRequestItem.parentId = res.data.pid;
                //console.log(this.deptItems);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
    //列表功能结束

    //下钻，清除搜索
    public onDown(id: String) {
        this.deptRequestItem.search = false;
        this.deptRequestItem.searchField = "all";
        this.deptRequestItem.searchKey = "";
        this.getList(1, id);
    }

    public openModal() {
        if (this.selectItem.parent < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        this.isOpen = true;
        this.cType = this.selectItem.cType || "";
        this.selected = {};
        if (!this.multiple) {
            //单选
            if (!this.$tools.isEmpty(this.selectItem.value))
                this.selected[this.selectItem.value] = {
                    parent: this.selectItem.parent,
                    title: this.selectItem.title,
                    value: this.selectItem.value,
                };
        } else {
            //多选
            for (const key in this.selectItem.value) {
                if (!this.$tools.isEmpty(this.selectItem.value[key].value)) {
                    this.selected[this.selectItem.value[key].value] = {
                        parent: this.selectItem.value[key].parent,
                        title: this.selectItem.value[key].title,
                        value: this.selectItem.value[key].value,
                    };
                }
            }
        }
        this.getList(0, this.selectItem.parent);
        this.$nextTick(() => {
            this.deptSelectModalElement.openModal();
        }).then();
    }

    public closeModal() {
        this.deptSelectModalElement.closeModal();
    }

    public toggleModal() {
        this.deptSelectModalElement.toggleModal();
    }

    get disBackBtn() {
        return this.deptRequestItem.parentId === this.maxParentId;
    }
    get disUpBtn() {
        if (this.deptPageItems.total <= 0) return true;
        return this.deptPageItems.current <= 1;
    }
    get disDownBtn() {
        if (this.deptPageItems.total <= 0) return true;
        return this.deptPageItems.current >= this.deptPageItems.pages;
    }
    private btnBackPage() {
        if (!this.disBackBtn) {
            this.deptRequestItem.search = false;
            this.deptRequestItem.searchField = "all";
            this.deptRequestItem.searchKey = "";
            this.getList(1, this.deptPageItems.pid);
        }
    }
    private btnUpPage() {
        if (!this.disUpBtn) {
            let page = this.deptPageItems.current - 1;
            if (page < 1) page = 1;
            this.getList(page, this.deptRequestItem.parentId);
        }
    }
    private btnDownPage() {
        if (!this.disDownBtn) {
            let page = this.deptPageItems.current + 1;
            if (page > this.deptPageItems.pages) page = this.deptPageItems.pages;
            this.getList(page, this.deptRequestItem.parentId);
        }
    }
}
